import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _57680cec = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _338bc064 = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _5a472a87 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _1c71b2b7 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _3bceef57 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _38fdf53f = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _fa655c86 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _4703cf58 = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _07a780e1 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _5a6c4a02 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _3a796d56 = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _18eca3fa = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _1fc3c8d7 = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _3018360f = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _09e8018c = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _820f40be = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _1a822b97 = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _3f2cf438 = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _3ca65578 = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _1a3d4341 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _2750810a = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _eeb59022 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _7c05bd1e = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _6d2e9338 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _1c47997e = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _5effeb1f = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _a1f25486 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _472ab4cb = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _3f78a9fc = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _8cb2966a = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _1358711e = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _becc8254 = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _5bd1ba18 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _a8c6a268 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _62c68332 = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _455fe0b7 = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _0aa06fb2 = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _492a6e2e = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _54e4252e = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _303d5335 = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _b8a8aa2c = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _e495e0fa = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _a4712074 = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _b2846e5c = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _9132ef08 = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _40f8cd96 = () => interopDefault(import('../pages/setting/import/Detail.vue' /* webpackChunkName: "pages/setting/import/Detail" */))
const _649e101e = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _dd138ad4 = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _65aa159d = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _01a068e5 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _4f243bda = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _190f2acc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1f2559ff = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _b6feef38 = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _f6a67082 = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _34bfaf80 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _1d4ed525 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _12fc04e0 = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _a436cd18 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _c507ad72 = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _a427b9f4 = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _20157ebe = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _5b452e8f = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _7da74b58 = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _1e47baa2 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _7c8e29b3 = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _5c2edb7f = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _499b70ee = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _d9e26584 = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _f3709020 = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _0eb2b244 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _30373ab2 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _c234e9f4 = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _b531463c = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _57680cec,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _338bc064,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _5a472a87,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _1c71b2b7,
    name: "calendar"
  }, {
    path: "/customer",
    component: _3bceef57,
    name: "customer"
  }, {
    path: "/details",
    component: _38fdf53f,
    name: "details"
  }, {
    path: "/goals",
    component: _fa655c86,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _4703cf58,
    name: "maisoku"
  }, {
    path: "/media",
    component: _07a780e1,
    name: "media"
  }, {
    path: "/privacy",
    component: _5a6c4a02,
    name: "privacy"
  }, {
    path: "/project",
    component: _3a796d56,
    name: "project"
  }, {
    path: "/response",
    component: _18eca3fa,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _1fc3c8d7,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _3018360f,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _09e8018c,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _820f40be,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _1a822b97,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _3f2cf438,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _3ca65578,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _1a3d4341,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _2750810a,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _eeb59022,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _7c05bd1e,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _6d2e9338,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _1c47997e,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _5effeb1f,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _a1f25486,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _472ab4cb,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _3f78a9fc,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _8cb2966a,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _1358711e,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _becc8254,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _5bd1ba18,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _a8c6a268,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _62c68332,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _455fe0b7,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _0aa06fb2,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _492a6e2e,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _54e4252e,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _303d5335,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _b8a8aa2c,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _e495e0fa,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _a4712074,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _b2846e5c,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _9132ef08,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/Detail",
    component: _40f8cd96,
    name: "setting-import-Detail"
  }, {
    path: "/setting/import/registration",
    component: _649e101e,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _dd138ad4,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _65aa159d,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _01a068e5,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _4f243bda,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _190f2acc,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _1f2559ff,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _b6feef38,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _f6a67082,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _34bfaf80,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _1d4ed525,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _12fc04e0,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _a436cd18,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _c507ad72,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _a427b9f4,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _20157ebe,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _5b452e8f,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _7da74b58,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _1e47baa2,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _7c8e29b3,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _5c2edb7f,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _499b70ee,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _d9e26584,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _f3709020,
    name: "property-property",
    children: [{
      path: "contact",
      component: _0eb2b244,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _30373ab2,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _c234e9f4,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _b531463c,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
